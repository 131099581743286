import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import batchService from "services/batchService";

import { ConfirmPopup } from "./components/ConfirmPopup";
import { StatusFilter } from "./components/Filter";
import { Paginator } from "./components/Paginator";
import { BatchTable } from "./components/table";
import {
  Content,
  PopupContent,
  SearchContent,
  SearchInput,
  StyledAlertDialog,
  TextInfoAlert
} from "./style";
import { formatData } from "./utils/formatData";

export const AlignmentWithSeller = () => {
  const { addPopup } = usePopup();
  const batchReturnId = new URLSearchParams(location.search).get(
    "batchReturnId"
  );
  const [batchDetail, setBatchDetail] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [openAlertBatchNotFound, setOpenAlertBatchNotFound] = useState(false);
  const { data, params, handleSearch, status, refresh, onChange, count } =
    useTableParams({
      service: params => batchService.getBatchesReturn(params),
      paramsDefault: { page: 1, statusId: 7, search: batchReturnId }
    });

  const handleConfirm = async data => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      await batchService.patchBatchReturn({ id, data });
      addPopup({ type: "success", title: "Análise confirmada" });
      onChange({ page: 1 });
      refresh();
      setOpenConfirm(false);
      return true;
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro confirmar análise",
        description: errorMessage
      });
      return false;
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      await batchService.deleteBatchReturn(id);
      addPopup({ type: "success", title: "Análise apagada" });
      onChange({ page: 1 });
      refresh();
      setOpenConfirm(false);
      return true;
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao apagar análise",
        description: errorMessage
      });
      return false;
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleOpenConfirm = batchId => {
    const result = batchData.find(({ id }) => id === Number(batchId));

    if (batchReturnId && !result) {
      setOpenAlertBatchNotFound(true);
    } else {
      setBatchDetail(result);
      setOpenConfirm(true);
    }
  };

  const handleCloseConfirm = () => {
    batchReturnId && setRedirect(true);
    setOpenConfirm(false);
  };

  const handleFilter = (status, stage) => {
    onChange({
      statusId: status || undefined,
      stageId: stage || undefined,
      page: 1
    });
  };

  const batchData = formatData(data);

  const handleCloseAlertBatchNotFound = () => {
    setOpenAlertBatchNotFound(false);
    setRedirect(true);
  };

  useEffect(() => {
    const isPending = status === "pending";
    if (!isPending && batchReturnId) {
      handleOpenConfirm(batchReturnId);
    }
  }, [batchData.length, status]);

  return (
    <ContainerPage id="back-to-top-anchor">
      <Box>
        <TitlePage>Alinhamento com o seller (Compras)</TitlePage>
      </Box>
      <Content>
        <SearchContent>
          <SearchInput
            placeholder="Seller, lote ou produto"
            onChange={handleSearch}
          />
          <StatusFilter
            statusId={params.statusId}
            stageId={params.stageId}
            handleFilter={handleFilter}
          />
        </SearchContent>
        <BatchTable
          batchData={batchData}
          loading={status === "pending"}
          handleOpenConfirm={handleOpenConfirm}
        />
        <Paginator
          handleChangePage={({ selected }) => onChange({ page: selected + 1 })}
          loading={status === "pending"}
          page={params.page}
          totalPages={Math.ceil(count / params.pageSize)}
        />
        <ConfirmPopup
          openConfirm={openConfirm}
          data={batchDetail}
          handleCloseConfirm={handleCloseConfirm}
          handleConfirm={handleConfirm}
          loading={updateLoading}
          handleDelete={handleDelete}
        />
      </Content>

      <StyledAlertDialog
        open={openAlertBatchNotFound}
        title="Aviso"
        handleClose={handleCloseAlertBatchNotFound}
      >
        <PopupContent>
          <TextInfoAlert>Alinhamento não encontrado</TextInfoAlert>
        </PopupContent>
      </StyledAlertDialog>
      {redirect && <Redirect to="/app/dashboard/kanban" />}
    </ContainerPage>
  );
};
